import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// import logo from "../images/rtj-logo.svg"

import { Container } from 'react-bootstrap';

const Header = ({ siteTitle }) => (
  <header className="text-center">
    <Link to="/">
      <StaticImage
      src="../images/rtj-logo.png"
      width={350}
      quality={50}
      placeholder="tracedSVG"
      formats={["auto", "webp", "avif"]}
      alt={siteTitle}
      className="logo img-fluid"
    />
      {/* <img src={logo} className="logo img-fluid" width="350" alt={siteTitle} /> */}
    </Link>
    <Container className="introSingleCol">
    <h1>Local and Family Owned</h1>
    <h2>RTJ Transportation, LLC is a local, family owned business that provides dump truck services to Savannah and the surrounding areas. </h2>
    </Container>
          
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
